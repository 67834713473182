@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Lato:900);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Darker+Grotesque:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mallanna&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*FONTS*/
.blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
}

.btn {
  cursor: pointer;
}

/* COLOR PALLET */
/* Social Colors */
/* MEDIA QUERY BREAK POINTS */
/*Main Font-sizes*/
body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
  color: #000;
  color: initial;
}

nav a {
  text-decoration: none;
  color: inherit;
  color: white;
}

button {
  font-family: "Quicksand", sans-serif;
}

.button-input {
  transition: opacity 0.2s;
}

.disable {
  opacity: 0;
  transition: all 0.5s;
}

.btn-post {
  background-color: #222222;
  color: white;
  width: auto;
  min-height: 10px;
  margin-top: 10px;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.btn-large {
  transition: all 0.2s;
  width: inherit;
  position: relative;
  top: 0px;
  width: 200px;
  background-color: #222222 !important;
}
.btn-large:active {
  position: relative;
  top: 3px;
}

.input-field input:focus {
  border-bottom: 1px solid #222222 !important;
  box-shadow: 0 1px 0 0 #222222 !important;
}
.input-field .active {
  color: #222222 !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

i {
  height: auto !important;
  height: initial !important;
}

label {
  font-family: "Quicksand", sans-serif;
  width: auto !important;
  width: initial !important;
}

.lato {
  font-family: "Lato", sans-serif;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
}

.logo {
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
}

h1 {
  font-size: 60px;
  line-height: 65px;
  font-family: "Lato", sans-serif;
}
@media only screen and (max-width: 700px) {
  h1 {
    font-size: 37.5px;
    line-height: 40.625px;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1257px) {
  h1 {
    font-size: 40px;
    line-height: 43.3333333333px;
  }
}

h2 {
  font-size: 30px;
  line-height: 35px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
@media only screen and (max-width: 700px) {
  h2 {
    font-size: 26px;
    line-height: 20px;
  }
}

h3 {
  font-size: 25px;
  line-height: 30px;
  font-family: "Quicksand", sans-serif;
}
@media only screen and (max-width: 700px) {
  h3 {
    font-size: 14.7058823529px;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1257px) {
  h3 {
    font-size: 16.6666666667px;
  }
}

h4 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 100;
  font-family: "Quicksand", sans-serif;
}

p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 300;
  font-family: "Quicksand", sans-serif;
  color: #314455;
  color: #222;
}
@media only screen and (max-width: 700px) {
  p {
    font-size: 14px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #222222;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-family: "Quicksand", sans-serif !important;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  top: -15px;
  /* Fade in tooltip */
  opacity: 0;
  transition: all 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #222222 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  top: -30px;
}

.display_none {
  display: none !important;
}

strong {
  font-weight: bold;
}

html {
  height: 100%;
}

body {
  background-color: #efefef;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

button,
input[type=submit],
input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
button:focus,
input[type=submit]:focus,
input[type=reset]:focus {
  background-color: inherit;
}

.main_container {
  height: 100%;
}
.main_container .main {
  height: 100%;
  max-width: 600px;
  text-align: center;
  margin: auto;
  background-color: #fff;
  position: relative;
}
.main_container .main nav {
  background-color: #efefef;
  box-shadow: none;
  box-shadow: initial;
  display: block;
  justify-content: center;
  padding: 30px;
  height: auto;
  margin: auto;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav {
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 15px;
    margin-bottom: 15px;
  }
}
.main_container .main nav .welcome {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main nav .welcome h4 {
  color: #222;
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
}
.main_container .main nav .welcome h4 span {
  font-weight: 900;
}
.main_container .main nav .welcome .profilePicture {
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 100%;
  margin-left: 10px;
  background-position: top;
  background-size: cover;
}
.main_container .main nav .welcome .profilePicture img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.main_container .main nav .churchInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav .churchInfo {
    justify-content: center;
    align-items: center;
  }
}
.main_container .main nav .churchInfo .logo {
  width: 50px;
  height: 50px;
  left: 10px;
}
.main_container .main nav .churchInfo .logo img {
  width: 100%;
}
.main_container .main nav .churchInfo .orgName {
  margin: 20px;
}
.main_container .main nav .churchInfo .orgName h3 {
  color: #222;
  margin: 0;
  margin: initial;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
}
@media only screen and (max-width: 700px) {
  .main_container .main nav .churchInfo .orgName h3 {
    font-size: 13px;
  }
}
.main_container .main .rsvpListContainer {
  height: 100%;
  width: 100%;
  position: relative;
}
.main_container .main .rsvpListContainer .availableDatesScreen {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: 50%;
  height: 100%;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen {
    overflow-y: scroll;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: 0px 3px 3px #efefef;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .title {
    background-color: #222;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .title h3 {
  font-family: "Darker Grotesque", sans-serif;
  margin: 0;
  margin: initial;
  color: #222;
  font-weight: 900;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .title h3 {
    color: #fff;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer {
  padding-top: 40px;
}
@media screen and (min-width: 1441px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer {
    overflow-y: scroll;
    max-height: 100vh;
    padding-bottom: 30vw;
  }
}
@media screen and (min-width: 1259px) and (max-width: 1440px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer {
    overflow-y: scroll;
    max-height: 100vh;
    padding-bottom: 30vw;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1257px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer {
    overflow-y: scroll;
    max-height: 100vh;
    padding-bottom: 30vw;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates {
  display: inline-block;
  width: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .dateContainer {
  width: 90%;
  margin: auto;
  padding-left: 10px;
  text-align: left;
  border-left: 2px solid #efefef;
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .dateContainer .dates h3 {
  margin: 0;
  margin: initial;
  display: block;
  font-weight: 900;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .dateContainer .dates h3 {
    font-size: 15px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .dateContainer .dates h4 {
  margin: 0;
  margin: initial;
  width: 100%;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .dateContainer .dates span {
  position: absolute;
  right: -4px;
  top: 10px;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #efefef;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events {
  padding: 0 30px;
  padding-bottom: 30px;
  border-radius: 10px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events {
    padding: 0 20px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event {
  position: relative;
  left: 0;
  background-color: #efefef;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: all ease 0.2s;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event:hover {
  background-color: #d6d6d6;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .desc {
  font-family: "Darker Grotesque", sans-serif;
  text-align: left;
  width: 85%;
  position: absolute;
  top: -20px;
  opacity: 0;
  padding: 10px 20px;
  margin: 0;
  margin: initial;
  transition: all 0.4s;
  width: 100%;
  border-radius: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .descShown {
  position: relative;
  top: -10px;
  opacity: 1;
  margin: 0;
  margin: initial;
  color: #222;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer {
  display: flex;
  background-color: inherit;
  border-radius: inherit;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  left: 0px;
  position: relative;
  z-index: 99;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .eventInfo {
  text-align: left;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .eventInfo h4 {
  color: #222;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer h4 {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .serviceName {
  font-family: "Darker Grotesque", sans-serif;
  text-align: left;
  text-transform: capitalize;
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .rsvpConfirmed {
  font-family: "Darker Grotesque", sans-serif;
  text-align: left;
  text-transform: capitalize;
  background-color: red;
  font-weight: 900;
  display: inline-block;
  padding: 0 10px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .rsvpConfirmed {
    font-size: 12px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .eventDesc {
  font-family: "Darker Grotesque", sans-serif;
  text-align: left;
  margin-top: 10px;
  color: #008000;
  font-weight: 900;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .eventDesc {
    font-size: 12px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .eventDesc i {
  color: #008000;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .serviceDetails h4 {
  color: #222;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .eventInfoContainer .serviceDetails .time {
  font-family: "Darker Grotesque", sans-serif;
  font-weight: 900;
  text-align: center;
  padding-left: 10px;
  width: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .errorDisplay {
  padding: 20px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .errorDisplay span {
  background: #f34336;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .errorDisplay span i {
  font-size: 30px;
  color: #ffffff;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .errorDisplay h4 {
  color: #f24336;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons {
  height: 50px;
  width: 100%;
  border-radius: 20px;
  position: absolute;
  top: -20px;
  opacity: 0;
  border-radius: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .btn {
  height: 100%;
  width: 100%;
  border-radius: 0;
  border-radius: initial;
  box-shadow: none;
  box-shadow: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .btn h4 {
  margin: 0;
  margin: initial;
  font-weight: 900;
  font-size: 15px;
  font-family: "Mallanna", sans-serif;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .unRsvp {
  background-color: #ff4f4f;
  border-bottom-left-radius: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .unRsvp:hover {
  background-color: #e01818;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .rsvp {
  background-color: #008000;
  border-bottom-left-radius: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .rsvp:hover {
  background-color: #0cb60c;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .no {
  background-color: #c7c7c7;
  border-bottom-right-radius: 10px;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .event .confirmRsvpButtons .options .no:hover {
  background-color: #a5a4a4;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .selectedEvent {
  background-color: #222;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .selectedEvent:hover {
  background-color: #363636;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .selectedEvent h4 {
  color: white !important;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .selectedEvent .check {
  background-color: #222;
  right: -10px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .notRsvpClick {
    left: 0px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .notRsvpClick h4 {
  color: white;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .notRsvpClick .rsvpConfirmed {
  color: white !important;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .notRsvpClick .rsvpConfirmed {
    font-size: 12px;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .availableDates .events .notRsvpClick .check {
  background-color: #4848f7;
  right: -30px;
}
@-webkit-keyframes load {
  0% {
    background-color: #dedcdc;
  }
  50% {
    background-color: #e8e7e7;
  }
  100% {
    background-color: #dedcdc;
  }
}
@keyframes load {
  0% {
    background-color: #dedcdc;
  }
  50% {
    background-color: #e8e7e7;
  }
  100% {
    background-color: #dedcdc;
  }
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .fake .stripe {
  width: 50%;
  background-color: #dedcdc;
  -webkit-animation: load 2s ease-in-out infinite;
          animation: load 2s ease-in-out infinite;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .fake .dates h3 {
  position: relative;
  color: transparent;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .fake .dates h3 .stripe {
  height: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  -webkit-animation: load 2s ease-in-out infinite;
          animation: load 2s ease-in-out infinite;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .fake h4 {
  position: relative;
}
.main_container .main .rsvpListContainer .availableDatesScreen .availableDatesContainer .fake h4 .stripe {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
@supports (-webkit-appearance: none) {
  .main_container .main .rsvpListContainer .availableDatesScreen {
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
}
.main_container .main .rsvpListContainer .bottomBox {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #4848f7;
  transition: all 0.4s;
  z-index: 99;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .bottomBox * {
  transition: all 0.4s;
}
.main_container .main .rsvpListContainer .bottomBox .churchName {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .bottomBox .churchName h4 {
  text-align: center;
  width: 80%;
  color: #fff;
  font-weight: 900;
  font-family: "Mallanna", sans-serif;
  margin: auto;
}
.main_container .main .rsvpListContainer .bottomBox .churchName h4 i {
  margin-left: 10px;
}
.main_container .main .rsvpListContainer .bottomBox .churchName .show-more-info {
  width: 100%;
  padding: 5px;
  background-color: #2c2ca5;
  margin: 5px 0;
}
.main_container .main .rsvpListContainer .bottomBox .more-info {
  margin-top: 10px;
  text-align: center;
  width: 80%;
  color: #fff;
  font-weight: 900;
  font-family: "Mallanna", sans-serif;
  margin: auto;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer {
  z-index: 100;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .confirmRsvpContainer {
    display: block;
  }
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp {
  width: 100%;
  background-color: #e2e2e2;
  z-index: 100;
  margin: auto;
  margin-bottom: 5px;
  transition: 0.5s;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
@media only screen and (max-width: 700px) {
  .main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp {
    width: 100%;
    border: 1px solid #efefef;
    box-shadow: none;
    box-shadow: initial;
  }
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #222;
  border-radius: 100%;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .close i {
  color: #fff;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo {
  position: relative;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition {
  padding: 30px 30px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header {
  text-align: left;
  margin: 10px 0;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header h3 {
  font-size: 20px;
  font-family: "Mallanna", sans-serif;
  font-weight: 900;
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition header h4 {
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers {
  text-align: left;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul .guestsHeaders {
  margin: 10px 0;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #222;
  cursor: pointer;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul .guestsHeaders span i {
  cursor: pointer;
  font-size: 15px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li h4 {
  margin: 0;
  margin: initial;
  font-family: "Mallanna", sans-serif;
  color: #222 !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .addMember {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid #222;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .addMember i {
  color: white;
  font-size: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .removeMember {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .familyMembers ul li .removeMember i {
  color: red;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests {
  text-align: left;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form {
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row {
  margin: 0;
  margin: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field {
  background-color: whitesmoke;
  margin: 15px 0;
  box-shadow: inset 0px 0px 3px #dad9d9;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input {
  border: medium none currentColor;
  border: initial;
  margin-bottom: 0;
  margin-bottom: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:active {
  border: medium none currentColor;
  border: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:focus-within {
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field input:focus {
  border: medium none currentColor;
  border: initial;
  border-bottom: 0 !important;
  border-bottom: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests form .row .input-field .active {
  left: 0;
  top: -5px;
  margin-bottom: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuests .submitGuest {
  background-color: #4848f7;
  padding: 10px;
  color: white;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuestsBtn {
  font-family: "Mallanna", sans-serif;
  background-color: #4848f7;
  color: white;
  padding: 2px 10px;
  display: inline-block;
  border-radius: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .confirmInfo .rsvpAddition .guests .addGuestsBtn h4 {
  margin: 0;
  margin: initial;
  font-size: 13px;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .btn {
  height: 50%;
  box-shadow: none;
  box-shadow: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 10px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .btn h4 {
  margin: 0;
  margin: initial;
  font-weight: 900;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .yes {
  background-color: #4CAF50;
  border-bottom-left-radius: 12px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .yes:hover {
  background-color: #409042;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .cancel {
  background-color: #c7c7c7;
  border-bottom-right-radius: 12px;
}
.main_container .main .rsvpListContainer .confirmRsvpContainer .confirmRsvp .options .cancel:hover {
  background-color: #a5a4a4;
}
.main_container .main .hidden-down {
  top: 300px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.main_container .main .screen .month h3 {
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}
.main_container .main .screen .calender .calenderTable {
  width: 90%;
  margin: auto;
}
.main_container .main .screen .calender .calenderTable table {
  height: 100%;
  width: 100%;
}
.main_container .main .screen .calender .calenderTable table .days tr {
  border-bottom: 1px solid #efefef;
}
.main_container .main .screen .calender .calenderTable table .days tr th {
  text-align: center;
  padding: 10px 0;
}
.main_container .main .screen .calender .calenderTable table tbody tr {
  border: medium none currentColor;
  border: initial;
}
.main_container .main .screen .calender .calenderTable table tbody tr td {
  padding: initial;
  padding: 10px;
  text-align: center;
  width: 14.28%;
  position: relative;
}
.main_container .main .screen .calender .calenderTable table tbody tr td::after {
  content: "";
  display: block;
  margin-top: 100%;
}
.main_container .main .screen .calender .calenderTable table tbody tr td .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin: initial;
}
.main_container .main .screen .calender .calenderTable table tbody tr .date {
  cursor: pointer;
}
@media screen and (min-width: 1259px) and (max-width: 1440px) {
  .main_container .main .screen .calender .calenderTable table tbody tr .date .content:hover {
    font-weight: 900;
    background-color: #6b6bde;
    color: #fff;
  }
}
@media screen and (min-width: 1441px) {
  .main_container .main .screen .calender .calenderTable table tbody tr .date .content:hover {
    font-weight: 900;
    background-color: #6b6bde;
    color: #fff;
  }
}
.main_container .main .addRsvp {
  position: -webkit-sticky;
  position: sticky;
  bottom: 30px;
  z-index: 9999;
  background-color: #6b6bde;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .main_container .main .addRsvp {
    width: 65px;
    height: 65px;
  }
}
.main_container .main .addRsvp h4 {
  color: white;
  margin: 0;
  margin: initial;
  font-weight: 900;
}
.main_container .main .addRsvp .rsvpCounter {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  transition: all 0.2s;
  transform: rotateY(90deg);
}
.main_container .main .addRsvp .rsvpCounter h4 {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
}
.main_container .main .addRsvp .showCounter {
  transform: rotateY(0deg);
}

/*# sourceMappingURL=main.css.map */

